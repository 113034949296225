import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment-timezone';

import { Box, Container, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, BurstModeOutlined, Pageview, Sort } from '@material-ui/icons';

import {
  GET_IMPORT_DICOM_STUDY_QUEUE,
  GET_REMOTE_PACS_LIST,
  IMPORT_DICOM_STUDIES,
} from '../../queries/RemotePacs/RemotePacs';
import Loading from '../Common/Loading';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { isEmpty } from '../../utils/ObjectUtils';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertContainerUI, AlertUI } from '../../componentsUI/Alert';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import ImportDicomList from './ImportDicomList';
import ImportDicomSearchForm from './ImportDicomSearchForm';
import ImportDicomDialog from './modal/ImportDicomDialog';
import ImportDicomSuccessDialog from './modal/ImportDicomSuccessDialog';
import { DATE_FORMAT, UTF8_DECODE, YEARS } from '../../utils/functions';
import Can from '../../casl/Can';
import { getLocalStorageJson } from '../../utils/ReadStorageUtils';

const useStyles = makeStyles(() => ({
  search: {
    padding: '.5em 1em',
  },
}));

const fieldNames = [
  { key: 'select', width: 10, type: 'icon' },
  { key: 'patientId', label: 'patient.id', width: 90, filter: true },
  { key: 'patientName', field: 'patientName', label: 'patient.name', format: UTF8_DECODE, width: 205, filter: true },
  { key: 'patientBirthDate', field: 'patientBirthDate', label: 'aage', width: 30, filter: true, format: YEARS, align: 'center' },
  { key: 'description', field: 'description', label: 'study.description', format: UTF8_DECODE, width: 205, filter: true },
  { key: 'modality', label: 'modality', width: 50, filter: true, align: 'center' },
  { key: 'numberOfSeries', label: 'series', width: 25, align: 'center' },
  { key: 'numberOfInstances', label: 'instances', width: 25, align: 'center' },
  { key: 'createdAt', field: 'createdAt', label: 'date', width: 75, format: DATE_FORMAT, align: 'center' },
];

const sortData = {
  default: { field: 'createdAt', direction: 'DESC' },
};

export const ImportDicomView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchFormRef = useRef(null);
  const hospital = useSelector((state) => state.hospital);
  const [selectedRemotePacs, setSelectedRemotePacs] = useState(null);
  const [studiesRequest, setStudiesRequest] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [importAction, setImportAction] = useState(0);
  const [importedPatient, setImportedPatient] = useState(null);
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const localImportStudyQueue = getLocalStorageJson('import-study', []);

  const { loading, data, error } = useQuery(
    GET_REMOTE_PACS_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (error && !serverError) setServerError(networkErrorParse(error));
  }, [error]);

  const addImportStudyRequest = (requestUuid) => {
    const queue = getLocalStorageJson('import-study', []);

    const requests = queue.map((item) => item.requestUuid);

    if (requests.includes(requestUuid)) return;

    queue.push({
      requestUuid,
      exp: moment().add(300, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    });

    localStorage.setItem('import-study', JSON.stringify(queue));
  };

  const [requestImportDicomStudies, { loading: updating }] = useMutation(IMPORT_DICOM_STUDIES, {
    onCompleted({ importDicomStudies }) {
      const { status, patient, studies } = importDicomStudies;
      studies.forEach((study) => addImportStudyRequest(study.requestUuid));

      if (status === 200) {
        setSelected([]);
        setStudiesRequest(null);
        toast(t('import.request.received.successfully'), { className: 'toast-success' });
        dispatch({ type: 'IMPORT_STUDY_QUEUE', importStudyQueue: { importStudyQueue: true } });
        return setImportedPatient(patient);
      }

      return toast(t('error.in.study.import.request'), { className: 'toast-error' });
    },
    onError: (err) => {
      const errorMessage = networkErrorParse(err).replace(/:/g, '.');

      setRequestError(errorMessage);
      toast(t(errorMessage), { className: 'toast-error' });
    },
    refetchQueries: [{
      query: GET_IMPORT_DICOM_STUDY_QUEUE,
      variables: {
        requestUuidList: localImportStudyQueue.map((item) => item.requestUuid),
      },
    }],
  });

  const remotePacsList = data && data.remotePacsList;
  const searchDisabled = isEmpty(remotePacsList) || searchLoading;
  const resetRequest = () => setRequestError(null);
  const resetPatient = () => setImportedPatient(null);
  const submitSearch = () => searchFormRef.current.submitForm();
  const submitImport = () => setImportAction(importAction + 1);
  const handleOrder = (event) => setAnchorEl(event.currentTarget);

  const buttons = [
    { name: 'search.studies', icon: Pageview, handleClick: submitSearch, disabled: searchDisabled },
    { name: 'import.studies', icon: BurstModeOutlined, handleClick: submitImport, disabled: selected && selected.length === 0 },
    { name: 'divider1', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const orderBy = {
    field: orderByField,
    direction: orderByDirection,
  };

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (!value.field) return;

    setOrderByDirection(value.field === orderByField
      ? reverseDirection()
      : value.direction || 'ASC');

    setOrderByField(value.field);
    setAnchorEl();
    setSelected([]);
  };

  const importDisabled = (() => {
    if (updating) return true;
    if (!selected) return false;
    return !selected.length;
  })();

  const handleImport = (values) => {
    if (importDisabled) return;

    const studies = values
      .filter((study, index) => selected.includes(index))
      .map((study) => ({
        studyInstanceUid: study.studyInstanceUid,
        description: study.description,
        patientId: study.patientId,
        patientName: study.patientName,
        patientBirthDate: study.patientBirthDate,
        modalities: study.modalities,
        numberOfInstances: study.numberOfInstances,
        createdAt: study.createdAt,
      }));

    if (!studies) {
      toast(t('no.studies.selected'), { className: 'toast-warning' });
      return;
    }

    requestImportDicomStudies({
      variables: {
        input: {
          remotePacsUuid: selectedRemotePacs.uuid,
          studies,
        },
      },
    }).then();
  };

  const multipleSelect = true;
  const handleSelect = (index) => {
    if (typeof index === 'undefined') {
      setSelected([]);
      return;
    }

    setSelected((selected.length && selected.includes(index))
      ? selected.filter((option) => option !== index)
      : [...multipleSelect ? selected : [], index]);
  };

  const handleSearch = async (values, { setErrors }) => {
    setServerError(null);
    setSearchError(null);
    handleSelect();
    try {
      setStudiesRequest(values);
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      setErrors(formikErrors);
    }
  };

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="import.dicom.studies" items={buttons} />
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>
      </Navbar>
      <ImportDicomDialog
        loading={updating}
        error={requestError}
        onClose={resetRequest}
      />
      <ImportDicomSuccessDialog
        patient={importedPatient}
        onClose={resetPatient}
      />
      <Container maxWidth="lg" className="article">
        <Box className={classes.search}>
          {serverError && (
            <AlertContainerUI>
              <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>
            </AlertContainerUI>
          )}
          {loading && isEmpty(remotePacsList) && <Loading />}
          {!loading && !serverError && isEmpty(remotePacsList) && (
            <AlertContainerUI>
              <AlertUI severity="info" title={t('no.pacs.configured')}>
                {t('there.is.not.any.pacs.available')}
                <br />
                <Can I="edit" this={hospital}>
                  {t('go.to')}
                  <Link to="/hospital/settings/remote-pacs">
                    {t('remote.pacs.settings')}
                  </Link>
                  {t('to.add.one.or.more.nodes')}
                </Can>
                <Can not I="edit" this={hospital}>
                  {t('request.your.admin.to.add.at.least.one.remote.pacs.settings')}
                </Can>
              </AlertUI>
            </AlertContainerUI>
          )}
          {!isEmpty(remotePacsList) && (
            <ImportDicomSearchForm
              items={remotePacsList}
              serverError={serverError}
              selected={selectedRemotePacs}
              setSelected={setSelectedRemotePacs}
              handleSubmit={handleSearch}
              formRef={searchFormRef}
              searchDisabled={searchLoading}
              setSearchDisabled={setSearchLoading}
            />
          )}
        </Box>
        {!!studiesRequest && (
          <ImportDicomList
            attributes={fieldNames}
            variables={studiesRequest}
            orderBy={orderBy}
            selected={selected}
            importAction={importAction}
            handleSelect={handleSelect}
            handleImport={handleImport}
            disabled={importDisabled}
            serverError={searchError}
            setServerError={setSearchError}
            searchDisabled={searchLoading}
            setSearchDisabled={setSearchLoading}
          />
        )}
      </Container>
    </ContainerUI>
  );
};

export default ImportDicomView;
