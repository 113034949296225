import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import Loading from '../../Common/Loading';
import { AlertUI, AlertWrapperUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    position: 'relative',
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
    '& .MuiSvgIcon-root.close': {
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
    },
  },
}));

const ImportDicomDialog = ({ loading, error, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={!!loading || !!error}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      {!loading && <Close onClick={handleClose} className="close" />}
      <DialogTitle>{t('requesting.import.of.selected.dicom.studies')}</DialogTitle>
      <DialogContent>
        {!error ? <Loading /> : (
          <AlertWrapperUI>
            <AlertUI severity="error" title="Error">{t(error)}</AlertUI>
          </AlertWrapperUI>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImportDicomDialog;
