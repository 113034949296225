import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import {GetFormat, PATIENT_NAME, PATIENT_NAME_CAPITALIZED} from '../../../utils/functions';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    position: 'relative',
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
    '& .MuiSvgIcon-root.close': {
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
    },
  },
  button: {
    margin: 8,
    marginBottom: 2,
    padding: '8px 20px',
    fontWeight: 700,
    '&:last-child': {
      marginRight: 2,
    },
  },
}));

const ImportDicomSuccessDialog = ({ patient, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const openPatient = () => navigate(`/patient/${patient.uuid}`);

  const patientName = GetFormat(patient, PATIENT_NAME_CAPITALIZED);

  return (
    <Dialog
      className={classes.dialog}
      open={!!patient}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <Close onClick={onClose} className="close" />
      <DialogTitle>{`${t('import.in.progress')}... `}</DialogTitle>
      <DialogContent>
        {t('import.request.received.successfully')}
        <br />
        {t('study.of.patient.will.be.available.in.a.few.minutes', { patient: patientName })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={onClose}
        >
          {t('keep.importing.studies')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={openPatient}
        >
          {t('see.patient.details')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDicomSuccessDialog;
