import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import { Box, Grid, Popover, Typography } from '@material-ui/core';
import { CheckCircle, Info, Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { ButtonUI } from '../../../../componentsUI/Button';
import Loading from '../../../Common/Loading';
import { ECHO_REMOTE_PACS_DICOM_NODE } from '../../../../queries/RemotePacs/RemotePacs';
import { networkErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import { parseGraphqlErrors } from '../../../../utils/FormikUtils';

const useLocalStyles = makeStyles(() => ({
  node: {
    '& .typography': {
      margin: '2px 4px',
    },
    '& .typography > button': {
      width: 100,
      minWidth: 100,
      height: 32,
      position: 'relative',
    },
    '& .typography > button svg': {
      position: 'absolute',
      left: 8,
    },
    '& .typography > button span': {
      position: 'absolute',
      right: 4,
    },
  },
  message: {
    width: 200,
    padding: 12,
    lineHeight: 1,
    fontSize: '.9em',
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  default: {
    color: theme.palette.primary.light,
    backgroundColor: 'white',
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  },
}));

export const DicomNodesTest = ({ remotePacs, type }) => {
  const { t } = useTranslation();
  const styles = useLocalStyles();
  const button = useButtonStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('default');
  const [message, setMessage] = useState(null);
  const [disabled, setDisable] = useState(false);

  const dicomNode = remotePacs.dicomNodes && remotePacs.dicomNodes.find((node) => node.type === type);

  const [echoRemotePacs, { loading }] = useMutation(
    ECHO_REMOTE_PACS_DICOM_NODE, {
      onCompleted({ dicomNodeEcho: response }) {
        setDisable(false);
        setMessage(response.message);
        setStatus(response.code === 200 ? 'success' : 'error');
      },
      onError: (error) => {
        setDisable(false);
        const networkError = networkErrorParse(error);
        if (networkError) {
          setMessage(networkError);
          setStatus('error');
        }
      },
    },
  );

  const handleRequest = async () => {
    setMessage();
    try {
      const variables = {
        remotePacsUuid: remotePacs.uuid,
        ip: dicomNode.ip,
        port: dicomNode.port,
        aeTitle: dicomNode.aeTitle,
      };
      await echoRemotePacs({ variables });
    } catch (e) {
      const errors = parseGraphqlErrors(e.graphQLErrors, t);
      if (
        Object.keys(errors).length === 0
        && errors.constructor === Object
      ) {
        setMessage(e);
      } else {
        setMessage(errors);
      }
    }
  };

  const resetStatus = () => {
    setMessage(null);
    setStatus('default');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisable(true);
    resetStatus();
    setTimeout(handleRequest, 1000);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(resetStatus, 5000);
  };

  const StatusItem = (() => {
    switch (status) {
      case 'success':
        return {
          icon: CheckCircle,
          message: 'OK',
        };
      case 'error':
        return {
          icon: Warning,
          message: 'Error',
        };
      case 'default':
      default:
        return {
          icon: Info,
          message: t('test'),
        };
    }
  })();

  const open = Boolean(anchorEl);
  const variant = status === 'default' ? 'outlined' : 'contained';
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid item xs={12} className={styles.node}>
      <Typography component="div" className="typography">
        <ButtonUI
          aria-describedby={id}
          variant={variant}
          disabled={disabled || loading}
          className={button[status]}
          onClick={handleClick}
          size="small"
        >
          <StatusItem.icon />
          <span>
            {StatusItem.message}
          </span>
        </ButtonUI>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        >
          <Box className={styles.message}>
            {message || (<Loading />)}
          </Box>
        </Popover>
      </Typography>
    </Grid>
  );
};
