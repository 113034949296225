import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DicomNodesTest } from '../../Hospital/Management/RemotePacs/DicomNodesTest';

const useLocalStyles = makeStyles(() => ({
  nodes: {
    '& .MuiChip-root': {
      margin: 2,
      height: 24,
      width: 90,
      '&.node': {
        width: 'calc(100% - 100px)',
        justifyContent: 'flex-start',
        fontSize: '.875em',
        fontWeight: 600,
      },
      borderRadius: 4,
      padding: 4,
      '& > span.MuiChip-label': {
        paddingLeft: 6,
        paddingRight: 6,
      },
    },
    '& .subheading': {
      marginTop: 4,
    },
  },
}));

const [TYPE_C_FIND, TYPE_C_MOVE] = [0, 1];

export const DicomNodeHeader = ({ remotePacs, node }) => {
  if (remotePacs.dicomNodes.length < 2) return 'NODE:';

  switch (node.type) {
    case TYPE_C_MOVE:
      return 'C-MOVE:';
    case TYPE_C_FIND:
      return 'C-FIND:';
    default:
      return 'NODE:';
  }
};

export const DicomNodesCard = ({ remotePacs, echoTest = false }) => {
  const { t } = useTranslation();
  const styles = useLocalStyles();

  return (
    remotePacs.dicomNodes.map((node, index) => (
      <Grid container spacing={2} key={index.toString()}>
        <Grid item xs={12} className={styles.nodes}>
          <Typography component="div" className="subheading">
            <DicomNodeHeader remotePacs={remotePacs} node={node} />
          </Typography>
          <Chip label={t('address')} color="primary" />
          <Chip label={node.ip} className="node" />
          <br />
          <Chip label={t('port')} color="primary" />
          <Chip label={node.port} className="node" />
          <br />
          <Chip label={t('ae.title')} color="primary" />
          <Chip label={node.aeTitle} className="node" />
          {echoTest && (<DicomNodesTest remotePacs={remotePacs} type={node.type} />)}
        </Grid>
      </Grid>
    ))
  );
};
