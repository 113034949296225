import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import { SEARCH_REMOTE_PACS_DICOM_STUDIES } from '../../queries/RemotePacs/RemotePacs';
import { StudiesGridTable } from '../Studies/StudiesGridTable';
import { useGridStyles } from '../Studies/styled/StudiesViewMakeStyles';
import { SelectedMultipleItems } from '../Common/StudyCard/StudiesGrid';
import { ButtonUI } from '../../componentsUI/Button';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

const SelectedIcon = ({ selected }) => (selected ? <CheckBox /> : <CheckBoxOutlineBlank />);

const ImportDicomList = ({
  attributes,
  variables,
  orderBy,
  selected,
  disabled,
  importAction,
  handleSelect,
  handleImport,
  serverError,
  setServerError,
  searchDisabled,
  setSearchDisabled,
}) => {
  const { t } = useTranslation();
  const { fixSidebar } = useSelector((state) => state && state.userInterface);
  const [count, setCount] = useState(importAction);
  const [sentinel, setSentinel] = useState();
  const styles = {
    offsetX: fixSidebar ? 285 : 0,
    offsetY: sentinel && sentinel.getBoundingClientRect().y,
    dark: false,
  };
  const classes = useGridStyles(styles);

  const [searchStudies, { loading, data, error }] = useLazyQuery(
    SEARCH_REMOTE_PACS_DICOM_STUDIES,
    {
      variables,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    searchStudies();
  }, [variables]);

  useEffect(() => {
    if (loading !== searchDisabled) setSearchDisabled(loading);
  }, [loading]);

  const studyList = data && data.remotePacsDicomStudies && data.remotePacsDicomStudies
    .sort((a, b) => (orderBy.direction === 'ASC'
      ? a[orderBy.field].localeCompare(b[orderBy.field])
      : b[orderBy.field].localeCompare(a[orderBy.field])
    ));

  const isDisabled = (study) => {
    if (!selected) return false;
    if (selected.length === 0) return false;
    return study.patientId !== studyList[selected[0]].patientId;
  };

  const dicomStudies = studyList && studyList.map((study) => ({ ...study, disabled: isDisabled(study) }));

  useEffect(() => {
    setServerError(networkErrorParse(error));
  });

  const handleClearSelected = (sel) => {
    handleSelect(sel);
  };

  const handleAction = () => {
    handleImport(dicomStudies);
  };

  if (count !== importAction) {
    setCount(importAction);
    handleAction();
  }

  return (
    <Grid container spacing={2}>
      <Container maxWidth="lg" ref={(i) => setSentinel(i)} className={classes.section}>
        <Container className={classes.selection}>
          {selected && selected.length > 0 && (
            <SelectedMultipleItems
              length={selected.length}
              label="dicom.study"
              onClick={() => handleClearSelected()}
            />
          )}
        </Container>
        <Container className={classes.main}>
          <Box className={classes.wrapper}>
            <Paper elevation={2} className={classes.box}>
              {serverError ? (
                <AlertWrapperUI>
                  <AlertUI severity="error" title={t('error')}>
                    {t(serverError)}
                  </AlertUI>
                </AlertWrapperUI>
              ) : (
                <StudiesGridTable
                  attributes={attributes}
                  items={dicomStudies}
                  loading={loading}
                  selected={selected}
                  handleGoto={handleSelect}
                  handleSelected={handleSelect}
                  SelectedIcon={SelectedIcon}
                />
              )}
            </Paper>
          </Box>
        </Container>
        <Container maxWidth="lg" className={classes.pagination}>
          <Box className={classes.listAction}>
            <ButtonUI
              variant="outlined"
              color="primary"
              disabled={disabled}
              onClick={handleAction}
            >
              {t('import.patient.selected.studies')}
            </ButtonUI>
          </Box>
        </Container>
      </Container>
    </Grid>
  );
};

export default ImportDicomList;
