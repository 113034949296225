import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { Form } from '../Common/styled/Form';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { TextFieldUI } from '../../componentsUI/TextField';
import InfoControlledAccordion from '../../componentsUI/InfoControlledAccordion';
import { AlertContainerUI, AlertUI } from '../../componentsUI/Alert';
import { ButtonUI } from '../../componentsUI/Button';
import { DicomNodesCard } from '../Common/DicomNodes/DicomNodesCard';
import { remotePacsSearchType } from '../../utils/RemotePacsUtils';

const useStyles = makeStyles((theme) => ({
  search: {
    padding: '.5em 1em',
  },
  paper: {
    marginTop: 8,
    padding: 16,
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  submit: {
    position: 'relative',
    marginLeft: 'auto',
    minHeight: 52,
    '& > button': {
      position: 'absolute',
      bottom: 8,
      right: 8,
    },
  },
  accordion: {
    position: 'relative',
    minHeight: 48,
    '&.MuiBox-root': {
      position: 'absolute',
      top: 8,
      left: 0,
    },
    '& .MuiAccordionSummary-root': {
      position: 'absolute',
      width: 'calc(100% - 26px)',
      zIndex: 22,
      borderRadius: 4,
      background: theme.palette.primary.dark,
    },
    '& .wrapper': {
      position: 'absolute',
      width: 'calc(100% - 28px)',
      left: 2,
      zIndex: 21,
      padding: 12,
      paddingTop: 40,
      background: theme.palette.background.paper,
      border: '1px solid',
      borderColor: theme.palette.primary.light,
      borderRadius: 4,
      '@media (max-width:1199px)': {
        width: 'calc(100% - 30px)',
      },
      '@media (max-width:1099px)': {
        width: 'calc(100% - 32px)',
      },
    },
  },
}));

const ImportDicomSearchForm = ({
  items,
  formRef,
  selected,
  serverError,
  setSelected,
  handleSubmit,
  searchDisabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const searchType = remotePacsSearchType.find((type) => selected && type.value === `${selected.searchType}`);
  const searchTypeLabel = searchType && `(${t(searchType.label)})`;

  const handleSelect = (item) => {
    if (item) localStorage.setItem('remote-pacs', item.uuid);
    setSelected(item);
  };

  useEffect(() => {
    if (!items) return;
    if (!selected && items.length === 1) handleSelect(items[0]);

    const localStoreRemotePacsUuid = localStorage.getItem('remote-pacs');
    if (!localStoreRemotePacsUuid) return;

    const remotePacs = items.find((pacs) => pacs.uuid === localStoreRemotePacsUuid);
    setSelected(remotePacs);
  }, [items]);

  const inputMinimumLength = 4;
  const validationSchema = Yup.object().shape({
    remotePacsUuid: Yup.string()
      .required(t('required.field')),
    patientId: Yup.string()
      .min(inputMinimumLength, t('id.too.short', { chars: inputMinimumLength }))
      .required(t('required.field')),
  });

  const AccordionDicomNodesContent = () => (selected ? (
    <Box className="wrapper">
      <DicomNodesCard remotePacs={selected} />
    </Box>
  ) : '');

  const handleAccordionChange = (panel) => (event, isExpanded) => setAccordionExpanded(isExpanded ? panel : false);
  const resetAccordionChange = () => setAccordionExpanded(false);

  const defaultRemotePacsOption = { id: 1, value: '', label: t('select.a.remote.pacs') };

  const getRemotePacsSelectData = (list) => ([
    defaultRemotePacsOption,
    ...list && list.map((pacs, index) => ({ id: (index + 1).toString(), value: pacs.uuid, label: pacs.serverName })),
  ]);

  const setRemotePacs = (uuid) => {
    if (!uuid) handleSelect(null);
    if (!items) handleSelect(null);
    if (uuid !== selected) resetAccordionChange();
    handleSelect(items.find((pacs) => pacs.uuid === uuid));
  };

  const initialValues = {
    remotePacsUuid: selected ? selected.uuid : '',
  };

  return (
    <Paper elevation={2} className={classes.paper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextFieldSelectUI
                  name="remotePacsUuid"
                  label={t('remote.pacs')}
                  props={props}
                  options={getRemotePacsSelectData(items)}
                  onClick={setRemotePacs}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldUI
                  name="patientId"
                  label={`${t('patient.id')} ${searchTypeLabel}`}
                  props={props}
                  classes={classes}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={8} className={classes.accordion}>
                <Box>
                  <InfoControlledAccordion
                    title={t('remote.pacs.settings')}
                    Icon={<InfoOutlined />}
                    Item={AccordionDicomNodesContent}
                    expanded={accordionExpanded === 'remote-pacs'}
                    onChange={handleAccordionChange('remote-pacs')}
                    disabled={!selected}
                  />
                </Box>
              </Grid>
              {serverError && (
                <Grid item xs={12}>
                  <AlertContainerUI>
                    <AlertUI title="Error" severity="error">
                      {serverError}
                    </AlertUI>
                  </AlertContainerUI>
                </Grid>
              )}
              <Grid item xs={12} sm={4} className={classes.submit}>
                <ButtonUI
                  variant="contained"
                  color="primary"
                  disabled={searchDisabled}
                  type="submit"
                >
                  {t('search.studies')}
                </ButtonUI>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default ImportDicomSearchForm;
