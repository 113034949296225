import React from 'react';

import ImportDicomView from '../../components/ImportDicom/ImportDicomView';
import { Layout } from '../../components/MenuLayout';

export default () => (
  <Layout>
    <ImportDicomView />
  </Layout>
);
